import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import PostPreview from '../components/PostPreview'
import Newsletter from '../components/Newsletter'
import styled from 'styled-components'

const Posts = styled.div`
  margin-top: 3rem;
  line-height: 1.5;
`

export default function Articles({ data, location }) {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMdx.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="All articles"
        keywords={[`blog`, `python`, `javascript`, `react`]}
      />
      <section>
        <h1>Articles</h1>
        <Posts>
          {posts.map(({ node }) => (
            <PostPreview post={node} key={node.fields.slug} collection="blog" />
          ))}
        </Posts>
      </section>
      <Newsletter referrer="articles page" />
    </Layout>
  )
}

export const pageQuery = graphql`
  query AllArticles($currentDate: Date!) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      filter: {
        fields: { collection: { eq: "blog" } }
        frontmatter: { published: { eq: true }, date: { lte: $currentDate } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 100
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            slug
          }
        }
      }
    }
  }
`
